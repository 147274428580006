import axios from "axios";

const isDev = window.location.hostname === "localhost";

const baseURL = isDev ? "http://localhost:8000" : " https://puma-comic.herokuapp.com";

export default axios.create({
    baseURL: baseURL,
    headers: {
        "Content-type": "application/json",
    }
})
