import { useState, useEffect } from 'react';

const isDev = window.location.hostname === "localhost";

const useHypermint = () => {
    const [contractInstance, setContractInstance] = useState(null);
    const [sdkLoaded, setSdkLoaded] = useState(false);


    useEffect(() => {
        console.log(`${isDev ? "Developer Mode" : "Production Mode"}`)

        const script = document.createElement('script');
        script.src = 'https://hypermint.com/client-sdk/client-sdk-evm.js';
        script.type = 'application/javascript';
        script.async = true;
        script.onload = () => {
            console.log('Client SDK loaded');
            setSdkLoaded(true);
        };
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (!sdkLoaded) return;

        const initializeContract = () => {
            const { Contract } = window.HyperMint;
            const contract = new Contract({
                contractId: isDev ? 'b934eb6b-f6c3-48c9-93e3-ffb37b2df1af' : "e8e179fb-990c-4a20-a1de-012bbf443635",
                enableLogging: true
            });
            setContractInstance(contract);
        };

        if (document.readyState === 'loading') {
            document.addEventListener('DOMContentLoaded', initializeContract);
        } else {
            initializeContract();
        }

        return () => {
            document.removeEventListener('DOMContentLoaded', initializeContract);
        };
    }, [sdkLoaded]);




    return contractInstance;
};

export default useHypermint;