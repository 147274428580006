
import { Routes, Route } from "react-router-dom";
import Claim from "../pages/Claim";



const Layout = (props) => {

  return <div className="min-h-screen h-full relative w-full overflow-x-hidden max-w-screen bg-black">

    <Routes>
      <Route
        path="/"
        element={<Claim />}
      />

    </Routes>

 
  </ div>
}

export default Layout;
