import http from "../http-common.js"

class DataService {


    getUserStatus(wallet_address) {
        return http.get(`/user/${wallet_address}/status`);
    }

    getSignature(wallet_address) {
        return http.get(`/user/${wallet_address}/signature`);
    }

    updateTransactionStatus(wallet_address, hash) {
        return http.post(`/user/${wallet_address}/transaction/${hash}`);
    }

}

export default new DataService();