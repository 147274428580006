
import './App.css';
import { BrowserRouter as Router} from "react-router-dom";
import Layout from './components/Layout';

function App() {
  console.log("Welcome to Puma Comic Book Claim v2")
  return (
    <div className="App">
      <Router>
        <Layout />
      </Router>
    </div>
  );
}

export default App;
